import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div>
        <div className="footer-inner">
          <img src="./images/logo2.svg" alt="logo" />
          <div className="footer-text1">
            Shapening the Future of Real Estate{" "}
          </div>
          <div className="footer-text2">
            No 32, Aje Street, Sabo Yaba, Lagos State
          </div>
          <div className="footer-text2">08162750523</div>
        </div>
        <div className="show-none">
          <div className="footer-social">
            <img src="./images/facebook.svg" alt="logo" />
            <img src="./images/linkedin.svg" alt="logo" />
            <img src="./images/instagram.svg" alt="logo" />
            <img src="./images/twitter.svg" alt="logo" />
          </div>
          <div className="footer-text1">
            {" "}
            &copy; 2023 Baker Homes. All rights Reserved
          </div>
        </div>
      </div>
      <div className="footer-inner2">
        <div className="footer-inner-flex">
          <Link className="footer-t" to="/project">
            <div>Projects</div>
          </Link>
          <Link className="footer-t" to="/about">
            {" "}
            <div>About us</div>
          </Link>
          <Link className="footer-t" to="/contact">
            {" "}
            <div>Contact</div>
          </Link>
        </div>
        <img
          style={{ cursor: "pointer" }}
          className="up"
          onClick={() =>
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
          src="./images/up.svg"
          alt="up"
        />
      </div>

      <div className="social-mobile">
        <div className="footer-social">
          <img src="./images/facebook.svg" alt="logo" />
          <img src="./images/linkedin.svg" alt="logo" />
          <img src="./images/instagram.svg" alt="logo" />
          <img src="./images/twitter.svg" alt="logo" />
        </div>
        <div className="footer-text1">
          {" "}
          &copy; 2023 Baker Homes. All rights Reserved
        </div>
      </div>
    </div>
  );
}
